import React, {useEffect} from 'react';
import axios from 'axios';

import logo from '../images/logo.png';


const Main = () => {

   //
   useEffect(()=>{
    checkBlocker();
  },[]);


  //
  const checkBlocker = () =>{
    axios.get('https://viakable-faf41-default-rtdb.firebaseio.com/permisos/p1.json')
  .then(result => {
      console.log(result.data.permiso);
      var acceso = result.data.permiso;
      if (acceso === "1"){ 
        document.getElementById("popup1").style.display = "none";
      }else{
        console.log("Aún no inicia la transmisión");
      }
  }).catch(e => console.log('error', e));
  }
  
  function showChat(){
    var w = document.getElementById('logos');
    var x = document.getElementById('close_btn');
    var y = document.getElementById('chat_window');
    var z = document.getElementById('video_window');

    if(y.style.display == "none"){
      y.style.display = "block";
      z.style.width = "75%";
      x.innerHTML = "CERRAR CHAT";
      w.style.display = "block";

    }
    else{
      y.style.display = "none";
      z.style.width = "100%";
      z.style.borderRight = "none";
      x.innerHTML = "ABRIR CHAT";
      w.style.display = "none";
    }
  }

  return (
    <div className="areaMain">

      <div className="popup" id="popup1">
        <div className="pop_box">
          <img src={logo}/>
          <h1>TRANSMISIÓN EN VIVO</h1>
          <p>Domingo 21 de mayo, 2023<br/>14:30 HRS.</p>
          <p className="instr_data">Actualiza tu navegador a esa hora</p>
        </div>
      </div>
      
      <div className="areaVideo" id="video_window">
        
        <button id="close_btn" onClick={showChat}>CERRAR CHAT</button>
       
        <div className="centerElement">

          <div id="logos">
            <img className="logo" src={logo} alt="Logo "/>
          </div>

          <div id="vid">
            <iframe src="https://vimeo.com/event/3402296/embed" title="video" frameBorder="0" allow="autoplay; picture-in-picture; fullscreen" allowFullScreen></iframe>
          </div>

          <p className="help_info">Si la transmisión en vivo se detiene o se congela, refresca el navegador.<br/>Powered by Ivoq</p>

        </div>

      </div>

      <div className="areaChat" id="chat_window">
          <iframe src="https://vimeo.com/event/3402296/chat/" width="100%" height="100%" title="chat" frameBorder="0"></iframe>
      </div>
      
   </div>
  );
  
}

export default Main;