import React from 'react';
import {Routes, Route } from 'react-router-dom';

import './elementos/estilos.css';

import Main from './elementos/main.js';

function App() {
  return (
    
      <Routes>
        <Route path="/" element={<Main/>}/>
      </Routes>

    
  );
}

export default App;
